.positiveActionButton:hover {

  background-color: #FFA53C;
  color: #444;
  border-color: #FF7C48;
  border-style: solid;
}
.positiveActionButton {
   background-color: #FF7C48;
  color: white;
}

.negativeActionButton {
    background-color: #232121;
    color: white;
}

.disabled {
    background-color: #CBCBCB; /* Light gray indicating inactivity */
    color: #9E9E9E; /* Dim text to signify unavailability */
    cursor: not-allowed;
    box-shadow: none; /* No shadow to flat the appearance */
  }
  