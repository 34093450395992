@tailwind base;
@tailwind components;
@tailwind utilities;
/* Spinner/loader CSS */
.loader {
  border-top-color: #3498db; /* Blue spinner */
  animation: spinner 1.5s linear infinite;
}

.App-banner {
  background-color: #FF7C48;
}
.logo {
 padding: 2em 2em;
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}